import { Card } from "src/components";
import styled from "styled-components";

export const Overlay = styled.div<{ opaque?: boolean }>`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  background-color: #00000066;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    background: ${(props) => (props.opaque ? "#00000066" : "transparent")};
    overflow: hidden;
  }
`;

export const ModalCard = styled(Card)<{ opaque?: boolean; maxWidth?: number }>`
  animation-name: grow-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  background: ${(props) => (props.opaque ? "transparent" : "white")};
  //min-width: max-content;
  min-height: max-content;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}%` : "40%")};
  max-height: 80%;
  height: 100%;
  scrollbar-width: none;
  box-shadow: ${(props) =>
    props.opaque ? "none" : "0px 0px 10px rgba(0, 0, 0, 0.1)"};
  @media (max-width: 1024px) {
    min-width: 100%;
    max-height: 100vh;
    z-index: 999;
    padding-bottom: 5rem;
    :hover {
      transform: none;
    }
    overflow-y: auto;
  }
`;
export const ModalChildContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @media (max-width: 1024px) {
    padding: 0.5rem;
  }
`;
