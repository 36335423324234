import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  CATEGORY_STORE,
  PROGRESS_CATEGORIES,
  SUB_CATEGORY_STORE,
} from "../../../management/reducers/AllCetegoryReducer";
const style = {
  position: "absolute",
  top: "60%",
  left: "50%",
  border: "none",
  width: "400px",
  outline: "none",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
};

export default function CreatePackType({
  createPackTypeOpen,
  handlePackTypeOpen,
  handlePackTypeClose,
  setCreatePackTypeOpen,
}) {
  const dispatch = useDispatch();
  const { register, reset, handleSubmit } = useForm();
  const [categorySearch, setCategorySearch] = useState("");
  const [subCategorySearch, setSubCategorySearch] = useState("");
  const { userLogin, category } = useSelector((state) => state);
  useEffect(() => {
    dispatch({
      type: PROGRESS_CATEGORIES,
      payload: {
        loading: true,
      },
    });
    fetch(
      `https://api.soillight.com/category?page=1&limit=500&search=${
        categorySearch || ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: PROGRESS_CATEGORIES,
          payload: {
            loading: false,
          },
        });
        // console.log(data)
        dispatch({
          type: CATEGORY_STORE,
          payload: {
            category: data.data,
          },
        });
      });
  }, [categorySearch, dispatch, userLogin?.user?.token]);
  useEffect(() => {
    dispatch({
      type: PROGRESS_CATEGORIES,
      payload: {
        loading: true,
      },
    });
    fetch(
      `https://api.soillight.com/sub/category?page=1&limit=500&search=${
        subCategorySearch || ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: PROGRESS_CATEGORIES,
          payload: {
            loading: false,
          },
        });
        //console.log(data)
        dispatch({
          type: SUB_CATEGORY_STORE,
          payload: {
            subCategory: data.data,
          },
        });
      });
  }, [subCategorySearch, dispatch, userLogin?.user?.token]);
  const handleClickSubCategory = (e) => {
    // console.log(e.target.value)
    dispatch({
      type: PROGRESS_CATEGORIES,
      payload: {
        loading: true,
      },
    });
    fetch(
      `https://api.soillight.com/sub/category/${e.target?.value}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: PROGRESS_CATEGORIES,
          payload: {
            loading: false,
          },
        });
        //console.log(data)
        dispatch({
          type: SUB_CATEGORY_STORE,
          payload: {
            subCategory: data.data,
          },
        });
      });
  };
  const createPackType = (data) => {
    dispatch({
      type: PROGRESS_CATEGORIES,
      payload: {
        loading: true,
      },
    });
    fetch("https://api.soillight.com/inside/pack/type", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userLogin?.user?.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        reset();
        dispatch({
          type: PROGRESS_CATEGORIES,
          payload: {
            loading: false,
          },
        });
        if (data.message) {
          toast(data.message);
        }
        if (data.error) {
          Object.values(data.error).forEach((err) => {
            toast(err);
          });
        }
      });
  };
  return (
    <div>
      <Modal
        style={{ overflowY: "scroll" }}
        open={createPackTypeOpen}
        onClose={handlePackTypeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={handlePackTypeClose}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create Pack Type
            </Typography>
            <MdCancel />
          </div>
          <form onSubmit={handleSubmit(createPackType)}>
            <Typography
              sx={{
                fontWeight: "bold",
                mb: 1,
                fontSize: 14,
                color: "#464646",
              }}
              style={{ fontFamily: `"Poppins", sans-serif` }}
            >
              Choose a Category:
            </Typography>
            <TextField
              fullWidth
              size="small"
              type="text"
              onChange={(e) => setCategorySearch(e.target.value)}
              placeholder="Search Category..."
            />
            <select
              id="category"
              style={{
                padding: "4px 10px",
                width: "100%",
                fontSize: "14px",
                color: "gray",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
              {...register("category", { min: 0 })}
              required
              onClick={(e) => handleClickSubCategory(e)}
            >
              {category?.category?.map((category, index) => (
                <option key={index} value={category?._id}>
                  {" "}
                  {category?.category}
                </option>
              ))}
            </select>
            <>
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: 14,
                  color: "#464646",
                }}
                style={{ fontFamily: `"Poppins", sans-serif` }}
              >
                Choose a Sub Category:
              </Typography>
              <TextField
                fullWidth
                size="small"
                type="text"
                onChange={(e) => setSubCategorySearch(e.target.value)}
                placeholder="Search Sub Category..."
              />
              <select
                id="subCategory"
                style={{
                  padding: "4px 10px",
                  width: "100%",
                  fontSize: "14px",
                  color: "gray",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
                {...register("subCategory", { min: 0 })}
                required
              >
                {category?.subCategory?.map((subCategory, index) => (
                  <option key={index} value={subCategory?._id}>
                    {" "}
                    {subCategory?.subCategory}
                  </option>
                ))}
              </select>
            </>

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: 14,
                  color: "#464646",
                }}
                style={{ fontFamily: `"Poppins", sans-serif` }}
              >
                Pack Type
              </Typography>
              <TextField
                fullWidth
                placeholder="Pack Type"
                size="small"
                {...register("packType", { min: 0 })}
                required
              />
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              {category?.loading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  style={{ textTransform: "capitalize", marginTop: "30px" }}
                  variant="contained"
                >
                  Create Pack Type
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
