import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export default function NewCampaignMedal({ open, onHide, onCreate }) {
  const [pushNotification, setPushNotification] = useState(true);
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [audience, setAudience] = useState("all");

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [dp, setDP] = useState("0");
  const [ed, setED] = useState(Date.now() + 3600000);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const handleReset = () => {
    setName("");
    setTitle("");
    setBody("");
    setDP("0");
    setED(Date.now() + 3600000);
    setPushNotification(true);
    setFreeDelivery(false);
    setDiscount(false);
    setAudience("all");
  };

  useEffect(() => {
    if (audience !== "buyer") {
      setPushNotification(true);
      setDiscount(false);
      setFreeDelivery(false);
    }
  }, [audience]);

  const createPromotion = () => {
    if (name === "") {
      return toast("Name is required.");
    }

    if (title === "") {
      return toast("Notification title is required.");
    }

    if (body === "") {
      return toast("Notification body is required.");
    }

    if (discount) {
      if (!Boolean(dp)) {
        return toast("Discount percentage is required.");
      } else {
        const p = parseInt(dp) || 0;

        if (p >= 100) {
          return toast("Discount percentage must be less than 100%.");
        }

        if (p <= 0) {
          return toast("Discount percentage must be greater than 0%.");
        }
      }
    }

    if (freeDelivery || discount) {
      if (ed === "") {
        return toast("Validity is required.");
      }
    }

    onHide();

    let data;

    if (discount) {
      data = {
        name,
        title,
        body,
        audience,
        type: "DISCOUNT",
        validity: ed,
        discountParentage: dp,
      };
    } else if (freeDelivery) {
      data = {
        name,
        title,
        body,
        audience,
        type: "FREE_DELIVERY",
        validity: ed,
      };
    } else {
      data = {
        name,
        title,
        body,
        audience,
        type: "PUSH_NOTIFICATION",
      };
    }

    toast.promise(
      axios.post(`https://api.soillight.com/promotions`, data, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      }),
      {
        pending: {
          render: "Creating promotion...",
        },
        success: {
          render({ data }) {
            handleReset();

            onCreate(data?.data?.promotion);

            return "Promotion successfully created and sent. to all user.";
          },
        },
        error: {
          render({ data }) {
            return data.message;
          },
        },
      }
    );
  };

  return (
    <Modal
      open={open}
      onClose={onHide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflowX: "scroll" }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          New Promotion
        </Typography>

        <Box sx={{ bgcolor: "#eee", mb: 2, px: 1 }}>
          <Typography id="modal-modal-title" variant="caption">
            Basic Info
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <TextField
            variant="standard"
            label="Promotion name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Audience</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={audience}
              label="Audience"
              onChange={(i) => setAudience(i.target.value)}
            >
              <MenuItem value={"all"}>All User</MenuItem>
              <MenuItem value={"seller"}>Seller</MenuItem>
              <MenuItem value={"buyer"}>Buyer</MenuItem>
              <MenuItem value={"rider"}>Rider</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ bgcolor: "#eee", mb: 2, px: 1 }}>
          <Typography id="modal-modal-title" variant="caption">
            Notification Info
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <TextField
            variant="standard"
            label="Title"
            fullWidth
            placeholder="Enter notification title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <TextField
            variant="standard"
            label="Body"
            fullWidth
            placeholder="Enter notification message"
            multiline
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </Box>

        <Box sx={{ bgcolor: "#eee", mb: 2, px: 1 }}>
          <Typography id="modal-modal-title" variant="caption">
            Promotion Type
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={pushNotification}
                onClick={() => {
                  setPushNotification(true);
                  setDiscount(false);
                  setFreeDelivery(false);
                }}
              />
            }
            label="Simple Push Notification"
          />
        </Box>
        {audience === "buyer" && (
          <>
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={freeDelivery} />}
                label="Free Delivery"
                checked={freeDelivery}
                onClick={() => {
                  setPushNotification(false);
                  setDiscount(false);
                  setFreeDelivery(true);
                }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={discount} />}
                label="Discount"
                checked={discount}
                onClick={() => {
                  setPushNotification(false);
                  setDiscount(true);
                  setFreeDelivery(false);
                }}
              />
            </Box>
          </>
        )}

        {discount && (
          <Box sx={{ mb: 2 }}>
            <TextField
              variant="standard"
              label="Discount percentage"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment>%</InputAdornment>,
              }}
              type="number"
              value={dp}
              onChange={(e) => setDP(e.target.value)}
            />
          </Box>
        )}

        {(freeDelivery || discount) && (
          <Box sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Validity"
                value={ed}
                onChange={(e) => setED(e)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" fullWidth />
                )}
                minDateTime={moment(Date.now())}
              />
            </LocalizationProvider>
          </Box>
        )}

        <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
          <Button
            color="error"
            variant="outlined"
            sx={{ mr: 1 }}
            onClick={() => {
              handleReset();
              onHide();
            }}
          >
            Discard
          </Button>
          <Button color="success" variant="contained" onClick={createPromotion}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
