import React from 'react';
import { ModalContextType } from '.';

import { initialState } from './reducer';

const ModalContext = React.createContext<ModalContextType>({
  state: initialState,
  openModal: () => null,
});

export default ModalContext;
