import styled, { css } from 'styled-components';
import { Row, Paragraph } from 'src/components';
import { theme } from 'src/utils/variables';

export const DropZone = styled(Row)<{ active: boolean }>`
  height: 4rem;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  display: flex;
  border-color: ${theme.primary[500]};
  background-color: transparent;
  ${({ active }) => {
    return (
      active &&
      css`
        color: red;
        svg,
        path {
          fill: rgb(169, 10, 15);
        }
        ${Row}, ${Paragraph} {
          color: rgb(169, 10, 15);
        }
        border-color: rgb(169, 10, 15);
        background-color: rgba(169, 10, 15, 0.1);
      `
    );
  }}
  align-items: center;
  justify-content: flex-start;
  border-width: 1.5px;
  border-radius: 8px;
  border-style: dashed;
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const LabelForInput = styled.label`
  margin: 0;
  padding: 0;
  display: flex;
`;

export const DropZoneActive = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
