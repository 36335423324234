import { Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo/soillightLogo.png";
import { login } from "../../management/actions/userActions";
import Loading from "../../management/Loading/Loading";
import ErrorMessage from "../../management/Message/ErrorMessage";
import SuccessMessage from "../../management/Message/SuccessMessage";
import "./auth.css";
function ForgetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { register, reset, handleSubmit } = useForm();
  const userLogin = useSelector((state) => state.userLogin);
  const { error, user } = userLogin;
  const [phone, setPhone] = useState("");
  const [token, setToken] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [otp, setOtp] = useState("");

  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);

  const sendOTP = (phone) => {
    return axios.post("https://api.soillight.com/forget-pass", {
      phone,
    });
  };

  const verifyOTP = (otp, phone) => {
    return axios.post("https://api.soillight.com/otp-verify", {
      otp,
      phone,
    });
  };

  const resetPassword = (password, token) => {
    return axios.put(
      "https://api.soillight.com/reset-pass",
      {
        password,
        password2: password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);

      if (step === 1) {
        await sendOTP(phone);
        setPhone(phone);

        setStep((prev) => prev + 1);
      } else if (step === 2) {
        const { data } = await verifyOTP(otp, phone);
        setToken(data?.token);

        setStep((prev) => prev + 1);
      } else if (step === 3) {
        if (pass === confirmPass) {
          const { data } = await resetPassword(pass, token);

          window.localStorage.setItem("user", JSON.stringify(data));

          window.location.href = "/";

          alert("Password successfully changed.");
        } else {
          alert("Password and Confirm password doesn't match.");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(
        error?.response?.data?.issue?.message ||
          error?.response?.data?.error?.phone ||
          error?.response?.data?.error?.otp ||
          error?.response?.data?.error?.password ||
          error.message
      );
    }
  };

  useEffect(() => {
    if (user?.message) {
      navigate("/dashboard/dashboard" || "/");
    }
  }, [navigate, user?.message]);

  return (
    <>
      <div className="auth-container">
        <div className="logo-head">
          <img sx={{ display: "block", margin: "0 auto" }} src={logo} alt="soillight logo" />
        </div>
        <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "400" }} gutterBottom component="div">
          Welcome Back!
        </Typography>
        <Grid container spacing={2} sx={{ alignItems: "center", justifyContent: "center" }}>
          <Grid item xs={10} md={4} style={{ marginTop: "20px" }}>
            {error && <ErrorMessage style={{ backgroundColor: "red" }}>{error}</ErrorMessage>}
            {user && <SuccessMessage style={{ backgroundColor: "red" }}>{user.message}</SuccessMessage>}
            <form onSubmit={onSubmit}>
              {step == 1 && (
                <div className="row">
                  <label htmlFor="phone">Phone number</label>
                  <input type="text" onChange={(e) => setPhone(e.target.value)} autoComplete="off" placeholder="Type your phone number" required />
                </div>
              )}

              {step == 2 && (
                <div className="row">
                  <label htmlFor="otp">One Time Password (OTP)</label>
                  <input type="text" onChange={(e) => setOtp(e.target.value)} autoComplete="off" placeholder="Type your otp code" required />
                </div>
              )}

              {step == 3 && (
                <>
                  <div className="row">
                    <label htmlFor="password">New Password</label>
                    <input onChange={(e) => setPass(e.target.value)} autoComplete="off" placeholder="Type your password" required />
                  </div>

                  <div className="row">
                    <label htmlFor="confirm-password">Confirm new password</label>
                    <input
                      type="text"
                      onChange={(e) => setConfirmPass(e.target.value)}
                      autoComplete="off"
                      placeholder="Type your password"
                      required
                    />
                  </div>
                </>
              )}

              {loading ? (
                <Loading />
              ) : (
                <button className="auth-btn" type="submit">
                  Next
                </button>
              )}

              <div
                style={{
                  textAlign: "center",
                }}
              ></div>
            </form>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ForgetPassword;
