import React from "react";

import { initialState } from "./reducer";

const SellerContext = React.createContext({
  state: initialState,
  getProducts: (data: { sellerId: string }) => {},
  getCategories: (data?: Record<string, any>) => {},
  getSubCategories: (data: { categoryId: string }) => {},
  getTypes: (data: { subCategoryId: string }) => {},
  getPackTypes: (data: { subCategoryId: string }) => {},
  createProduct: (data: {
    payload: Record<string, any>;
    sellerId: string;
  }) => {},
  updateProduct: (data: {
    payload: Record<string, any>;
    productId: string;
    sellerId?: string;
  }) => {},
  deleteProduct: (data: { productId: string; sellerId?: string }) => {},
  clearTypeState: () => {},
  clearSizeState: () => {},
  clearError: () => {},
  clearSuccess: () => {},
  clearProductState: () => {},
});

export default SellerContext;
