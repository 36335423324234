import styled, { css } from 'styled-components';
import { font, theme } from 'src/utils/variables';
import { ButtonProps } from 'src/types';

export const BaseButton = styled.button<ButtonProps>`
  ${({ variant, background = 'primary', color = 'white', active = true }) => {
    const bg = Object.keys(theme).includes(background)
      ? active ? theme[background][500] :  theme[background][400]
      : background;
    const col: string = Object.keys(theme).includes(color)
      ? theme[color][100]
      : color;

    switch (variant) {
      case 'solid':
        return css`
          border: none;
          background-color: ${bg};
          color: ${col};
          svg,
          path,
          * {
            stroke: ${col};
            color: ${col};
          }
        `;
      case 'outlined':
        return css`
          background-color: transparent;
          color: ${bg};
          border: 1px solid ${bg};
          svg,
          path,
          * {
            color: ${col};
            stroke: ${bg};
          }
        `;
      case 'text':
        return css`
          background-color: transparent;
          border: none;
          color: ${bg};
          svg,
          path,
          * {
            color: ${col};
            stroke: ${bg};
          }
        `;
      default:
        return css`
          border: none;
          background-color: ${bg};
          color: ${col};
          svg,
          path,
          * {
            color: ${col};
            stroke: ${col};
          }
        `;
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: ${font.sizes.base};
  font-weight: ${font.weights.semibold};
  width: 100%;
  cursor: pointer;
`;

export const IconButton = styled(BaseButton)<
  ButtonProps & { toolTipTitle?: string }
>`
  padding: 0;
  height: ${({ size }) => size || '3.3125rem'};
  width: ${({ size }) => size || '3.3125rem'};

  ${({ color, background }) => {
    return css`
      background: ${theme[background ?? 'white'][100]};
      svg,
      path,
      * {
        color: ${theme[color ?? 'white'][500]};
        stroke: ${theme[color ?? 'white'][500]};
      }
    `;
  }};

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem;
  &:hover {
    ${({ toolTipTitle }) => {
      return (
        toolTipTitle &&
        css`
          &::after {
            font-size: ${font.sizes.xs};
            content: '${toolTipTitle ?? ''}';
            width: max-content;
            position: absolute;
            top: -2.5rem;
            background-color: ${theme.grey[500]};
            font-weight: ${font.weights.bold};
            color: ${theme.white[100]};
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
          }
        `
      );
    }}
  }
`;
