import React, { useEffect } from "react";
import { useSeller } from "src/state";
import { useSnackbar } from "notistack";

export const ErrorHandler: React.FC = ({ children }) => {
  const { state: sellerState, clearError: clearSellerError } = useSeller();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (sellerState.error) {
      enqueueSnackbar(sellerState.error, {
        variant: "error",
        autoHideDuration: 2000,
      });
      clearSellerError();
    }
  }, [sellerState.error]);

  return <>{children}</>;
};
