import _ from "lodash";
import { Text, Column, Loader, Row } from "src/components";
import { TableProps } from "src/types";
import { Pagination, TableContainer, TableContent } from "./styled";
import { PaginationComp } from "../Pagination";

export const Table: React.FC<TableProps> = ({
  data,
  columns,
  pagination,
  contentLoading = false,
  handleGoToPage,
  ...props
}) => {
  let startIndex = 0;
  let endIndex = data.length - 1;

  if (pagination) {
    startIndex = (pagination.page - 1) * pagination.size;
    endIndex = startIndex + pagination.size;
  }

  const paginatedData = data.slice(startIndex, endIndex);

  return (
    <Column gap={1}>
      <TableContainer>
        <TableContent {...props}>
          <table>
            <thead>
              <tr>
                {columns.map(({ title }, index) => (
                  <th key={index + title}>{title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((row, i) => {
                return (
                  <tr key={`${i}table-body`}>
                    {columns.map(({ dataIndex, render }, j) => {
                      const value = _.get(row, dataIndex, "");
                      return (
                        <td key={`${j}table-data`}>
                          {render ? render(value, row) : `${value}`}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableContent>

        {contentLoading && (
          <>
            <br />
            <Row justify="center">
              {" "}
              <Loader />
            </Row>
          </>
        )}

        {!pagination && (
          <Pagination>
            <span>
              Has <Text weight="bold">{data.length}</Text> item(s).
            </span>
          </Pagination>
        )}
        {pagination && <PaginationComp {...{ pagination, handleGoToPage }} />}
      </TableContainer>
    </Column>
  );
};
