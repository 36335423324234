import { Box, Button, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { PROGRESS_CATEGORIES } from "../../../management/reducers/AllCetegoryReducer";
import Loading from "../Sheard/Loading";
import axios from "axios";
const style = {
  position: "absolute",
  top: "60%",
  left: "50%",
  border: "none",
  width: "400px",
  outline: "none",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
};

export default function CategoryDetails({
  handleCategoryDetailsClose,
  handleSingleClick,
  handleCategoryDetailsOpen,
  categoryDetailsOpen,
}) {
  const { register, reset, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { userLogin, category } = useSelector((state) => state);
  // const { categoryDetailsOpen } = category;
  const [name, setName] = useState("");
  // console.log(categoryDetailsOpen)
  const [previewSource, setPreviewSource] = useState();
  const [isLoading, setLoading] = useState(false);
  // console.log(groupData.error)
  // console.log(categoryInfo)

  useEffect(() => {
    if (categoryDetailsOpen) {
      setName(categoryDetailsOpen?.category);
    }

    console.log(categoryDetailsOpen);
  }, [categoryDetailsOpen]);

  const handleFileSelect = (e) => {
    if (e.target?.files?.length) {
      const file = e.target?.files[0];
      setPreviewSource(file);
    }
  };

  const updateCategory = (data) => {
    if (!categoryDetailsOpen?._id) return;
    setLoading(true);
    if (previewSource) data.img = URL.createObjectURL(previewSource);

    const formData = new FormData();

    formData.append("img", previewSource || categoryDetailsOpen?.img);
    formData.append("category", data.category);
    // formData.append("age", data.age);

    axios
      .put(
        `https://api.soillight.com/category/${categoryDetailsOpen?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userLogin?.user?.token}`,
          },
        }
      )
      .then(({ data }) => {
        reset();
        setLoading(false);

        if (data.message) {
          window.location.reload();
          toast(data.message);
        }
        if (data.error) {
          Object.values(data.error).forEach((err) => {
            toast(err);
          });
        }
      })
      .catch((err) => {
        setLoading(false);

        toast(err.message);
      });
  };

  const deleteCategory = () => {
    if (!categoryDetailsOpen?._id) return;
    setLoading(false);

    fetch(
      `https://api.soillight.com/category/${categoryDetailsOpen?._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        reset();
        setLoading(false);

        if (data.message) {
          toast(data.message);
          window.location.reload();
        }
        if (data.error) {
          Object.values(data.error).forEach((err) => {
            toast(err);
          });
        }
      });
  };
  return (
    <div>
      <Modal
        style={{ overflowY: "scroll" }}
        open={categoryDetailsOpen}
        onClose={() => {
          handleCategoryDetailsClose();
          reset();
          setPreviewSource(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={() => {
              handleCategoryDetailsClose();
              reset();
              setPreviewSource(null);
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Category
            </Typography>
            <MdCancel />
          </div>
          <form onSubmit={handleSubmit(updateCategory)}>
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: 14,
                  color: "#464646",
                }}
                style={{ fontFamily: `"Poppins", sans-serif` }}
              >
                Category Name
              </Typography>
              <TextField
                fullWidth
                placeholder={categoryDetailsOpen?.category}
                defaultValue={name}
                size="small"
                {...register("category", { min: 0 })}
                required
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "10px 0px",
              }}
            >
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  position: "absolute",
                  zIndex: "-1",
                  borderRadius: "100%",
                }}
                src={
                  previewSource
                    ? URL.createObjectURL(previewSource)
                    : categoryDetailsOpen?.img ||
                      "https://dummyimage.com/100x100/aaa/000"
                }
                alt="chosen"
              />
              <label
                style={{
                  opacity: 0,
                  background: "transparent",
                  cursor: "pointer",
                  padding: "40px",
                  border: "none",
                }}
              >
                <input
                  sx={{
                    color: "white",
                    opacity: 0,
                    height: "100px",
                    padding: "30px 30px!important",
                  }}
                  onChange={handleFileSelect}
                  type="file"
                />
              </label>
            </Box>

            <Box display="flex" justifyContent="center">
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize", marginTop: "30px" }}
                    variant="contained"
                  >
                    Update
                  </Button>
                  <Button
                    onClick={deleteCategory}
                    style={{
                      textTransform: "capitalize",
                      marginTop: "30px",
                      marginLeft: "20px",
                      background: "red",
                    }}
                    variant="contained"
                  >
                    Delete
                  </Button>
                </>
              )}
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
