import styled from 'styled-components';

export const LoaderMain = styled.div`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid  #a4f2c8;
  border-top-color:#05AC54;
  animation: spin 0.2s infinite linear;
`;
