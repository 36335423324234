import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  CATEGORY_STORE,
  PROGRESS_CATEGORIES,
} from "../../../management/reducers/AllCetegoryReducer";
import Select from "react-select";
import axios from "axios";
import Loading from "../Sheard/Loading";

const style = {
  position: "absolute",
  top: "60%",
  left: "50%",
  border: "none",
  width: "400px",
  outline: "none",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
};

export default function CreateSubCategory({
  subCategoryDetailsOpen,
  handleSubCategoryDetailsClose,
}) {
  const subCategory = subCategoryDetailsOpen;

  const { register, reset, handleSubmit } = useForm();

  const { userLogin, category } = useSelector((state) => state);

  const [categorySearch, setCategorySearch] = useState("");

  const [selected, setSelected] = useState("");

  const [previewSource, setPreviewSource] = useState("");

  const [selectedCat, setSelectedCat] = useState();

  const [options, setOptions] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const handleFileSelect = (e) => {
    if (e.target?.files?.length) {
      setPreviewSource(e.target?.files[0]);
    }
  };

  useEffect(() => {
    fetch(
      `https://api.soillight.com/category?page=1&limit=500&search=${
        categorySearch || ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const cOptions = [];

        for (let c of data?.data) {
          cOptions.push({
            value: c._id,
            label: c.category,
          });
        }

        setOptions(cOptions);
      });
  }, []);

  const updateSubCategory = (data) => {
    if (!subCategoryDetailsOpen?._id) return;
    setLoading(true);

    const formData = new FormData();

    console.log("#####################");
    console.log(selectedCat || subCategory);
    console.log("#####################");

    formData.append("img", previewSource || subCategoryDetailsOpen?.img);
    formData.append("subCategory", data.subCategory);
    formData.append("category", selectedCat || subCategory.category);

    axios
      .put(
        `https://api.soillight.com/sub/category/${subCategoryDetailsOpen?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userLogin?.user?.token}`,
          },
        }
      )
      .then(({ data }) => {
        reset();
        setLoading(false);

        if (data.message) {
          toast(data.message);

          window.location.reload();
        }
        if (data.error) {
          Object.values(data.error).forEach((err) => {
            toast(err);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast(error.message);
      });
  };

  const deleteSubCategory = () => {
    if (!subCategoryDetailsOpen?._id) return;
    setLoading(true);
    fetch(
      `https://api.soillight.com/sub/category/${subCategoryDetailsOpen?._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        reset();
        setLoading(false);

        if (data.message) {
          handleSubCategoryDetailsClose();
          toast(data.message);
          window.location.reload();
        }
        if (data.error) {
          Object.values(data.error).forEach((err) => {
            toast(err);
          });
        }
      });
  };

  return (
    <div>
      <Modal
        style={{ overflowY: "scroll" }}
        open={subCategoryDetailsOpen}
        onClose={handleSubCategoryDetailsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={handleSubCategoryDetailsClose}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Sub Category
            </Typography>
            <MdCancel />
          </div>
          <form onSubmit={handleSubmit(updateSubCategory)}>
            <Typography
              sx={{
                fontWeight: "bold",
                mb: 1,
                fontSize: 14,
                color: "#464646",
              }}
              style={{ fontFamily: `"Poppins", sans-serif` }}
            >
              Category
            </Typography>

            {Boolean(options.length) ? (
              <Select
                options={options}
                defaultValue={options.find(
                  (o) => o.value === subCategory.category
                )}
                onChange={(i) => {
                  setSelectedCat(i.value);
                }}
              />
            ) : (
              "Loading..."
            )}

            <Box mt={2}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: 14,
                  color: "#464646",
                }}
                style={{ fontFamily: `"Poppins", sans-serif` }}
              >
                Sub Category Name
              </Typography>
              <TextField
                fullWidth
                placeholder="Sub Category Name"
                size="small"
                {...register("subCategory", { min: 0 })}
                required
                defaultValue={subCategory.subCategory}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "10px 0px",
              }}
            >
              <>
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    position: "absolute",
                    zIndex: "-1",
                    borderRadius: "100%",
                  }}
                  src={
                    previewSource
                      ? URL.createObjectURL(previewSource)
                      : subCategoryDetailsOpen?.img ||
                        "https://dummyimage.com/100x100/aaa/000"
                  }
                  alt="chosen"
                />
                <label
                  style={{
                    opacity: 0,
                    background: "transparent",
                    cursor: "pointer",
                    padding: "40px",
                    border: "none",
                  }}
                >
                  <input
                    sx={{
                      color: "white",
                      opacity: 0,
                      height: "100px",
                      padding: "30px 30px!important",
                    }}
                    onChange={handleFileSelect}
                    type="file"
                    accept="image/*"
                  />
                </label>
              </>
            </Box>

            <Box display="flex" justifyContent="center">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Button
                  type="submit"
                  style={{ textTransform: "capitalize", marginTop: "30px" }}
                  variant="contained"
                >
                  Update
                </Button>

                <Button
                  onClick={deleteSubCategory}
                  style={{
                    textTransform: "capitalize",
                    marginTop: "30px",
                    marginLeft: "20px",
                    background: "red",
                  }}
                  variant="contained"
                >
                  Delete
                </Button>
              </>
            )}
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
