import styled from 'styled-components';


export const Radio = styled.input`
appearance: none;
  margin: 0;
  font: inherit;
  color: white;
  width: 0.9rem;
  height: 0.9rem;
  background: #FFFFFF;
  border: 1.5375px solid #562C62;
  border-radius: 100%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: "";
    width: 0.9em;
    height: 0.9em;
    border-radius: 100%;
    transform: scale(0);
    transition: 50ms transform linear;
    border: 4px solid #F3875E;
  }
  &:checked {
    &::before {
        transform: scale(1);
      }
  }
`;

export const Checkbox = styled.input`
appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  color: white;
  border: 2.4375px solid #562C62;
  width: 1.7rem;
  height: 1.7rem;
  background: #FFFFFF;
  border-radius: 100%;
  transform: translateY(-0.075em);
  display: flex;
  justify-content: center;
  &::before {
    content: "";
    width: 0.9em;
    height: 0.9em;
    border-radius: 100%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: #552C62;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }
  &:checked {
    // &::before {
    //     transform: scale(1);
    //   }
      border: 7px solid #F3875E;
    }
`;