import React, { useEffect, useState } from "react";
import { Row } from "src/components";
import { useModal } from "src/state";
import { CancelIcon } from "src/assets/icons";
import { Overlay, ModalCard, ModalChildContainer } from "./styled";

export const Modal: React.FC<{
  visible?: boolean;
  dismissible?: boolean;
  opaque?: boolean;
  dismiss?: (visible: boolean) => void;
}> = ({
  visible = true,
  dismiss,
  dismissible = true,
  opaque = false,
  children,
  ...rest
}) => {
  const { state, openModal } = useModal();
  const [isVisible, setIsVisible] = useState(visible);
  const handleDismiss = () => {
    if (dismiss) {
      dismiss(false);
    }
    setIsVisible(false);
    openModal();
  };
  useEffect(() => {
    if (!visible) {
      openModal();
    }
    setIsVisible(visible);
  }, [visible, openModal]);

  return isVisible ? (
    <Overlay opaque={opaque}>
      <ModalCard opaque={opaque} maxWidth={60} {...rest}>
        <Row align="center" justify="flex-end">
          {dismissible && <CancelIcon onClick={handleDismiss} />}
        </Row>
        <ModalChildContainer>{children}</ModalChildContainer>
      </ModalCard>
    </Overlay>
  ) : (
    <></>
  );
};
