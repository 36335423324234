import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { PROGRESS_CATEGORIES } from "../../../management/reducers/AllCetegoryReducer";
import axios from "axios";

const style = {
  position: "absolute",
  top: "60%",
  left: "50%",
  border: "none",
  width: "400px",
  outline: "none",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
};

export default function CreateCategory({
  createCategoryOpen,
  handleCategoryOpen,
  handleCategoryClose,
  setCategoryOpen,
}) {
  const { register, reset, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { userLogin, category } = useSelector((state) => state);
  const [previewSource, setPreviewSource] = useState(null);

  const handleSelectFile = (e) => {
    if (e.target?.files?.length) {
      setPreviewSource(e.target?.files[0]);
    }
  };

  const createCategory = (data) => {
    dispatch({
      type: PROGRESS_CATEGORIES,
      payload: {
        loading: true,
      },
    });

    const formData = new FormData();

    formData.append("category", data.category);
    formData.append("img", previewSource);

    axios
      .post(`https://api.soillight.com/category`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      })
      .then(({ data }) => {
        reset();
        dispatch({
          type: PROGRESS_CATEGORIES,
          payload: {
            loading: false,
          },
        });
        if (data.message) {
          toast(data.message);
          setPreviewSource(null);
        }
        if (data.error) {
          Object.values(data.error).forEach((err) => {
            toast(err);
          });
        }
      });
  };
  return (
    <div>
      <Modal
        style={{ overflowY: "scroll" }}
        open={createCategoryOpen}
        onClose={handleCategoryClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={handleCategoryClose}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create Category
            </Typography>
            <MdCancel />
          </div>
          <form onSubmit={handleSubmit(createCategory)}>
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: 14,
                  color: "#464646",
                }}
                style={{ fontFamily: `"Poppins", sans-serif` }}
              >
                Category Name
              </Typography>
              <TextField
                fullWidth
                placeholder="Category Name"
                size="small"
                {...register("category", { min: 0 })}
                required
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "10px 0px",
              }}
            >
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  position: "absolute",
                  zIndex: "-1",
                  borderRadius: "100%",
                }}
                src={
                  previewSource
                    ? URL.createObjectURL(previewSource)
                    : "https://dummyimage.com/100x100/aaa/000"
                }
                alt="chosen"
              />
              <label
                style={{
                  opacity: 0,
                  background: "transparent",
                  cursor: "pointer",
                  padding: "40px",
                  border: "none",
                }}
              >
                <input
                  sx={{
                    color: "white",
                    opacity: 0,
                    height: "100px",
                    padding: "30px 30px!important",
                  }}
                  onChange={handleSelectFile}
                  type="file"
                />
              </label>
            </Box>

            <Box display="flex" justifyContent="center">
            {category?.loading ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                style={{ textTransform: "capitalize", marginTop: "30px" }}
                variant="contained"
              >
                Create Category
              </Button>
            )}
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
