import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdCancel, MdFamilyRestroom } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { PROGRESS_CATEGORIES } from "../../../management/reducers/AllCetegoryReducer";
import Select from "react-select";
import axios from "axios";

const style = {
  position: "absolute",
  top: "60%",
  left: "50%",
  border: "none",
  width: "400px",
  outline: "none",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
};

export default function InsideSubCategoryDetails({
  handlePackTypeDetailsClose,
  packTypeOpen,
}) {
  const packType = packTypeOpen;

  const { register, reset, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { userLogin } = useSelector((state) => state);

  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [selectedCat, setSelectedCat] = useState(packType.category);
  const [selectedSubCat, setSelectedSubCat] = useState(packType.subCategory);
  const [nscf, setNscf] = useState(false);

  const loadCategories = () => {
    setNscf(false);
    fetch(
      `https://api.soillight.com/category?page=1&limit=500&search=`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const cOptions = [];

        for (let c of data?.data) {
          cOptions.push({
            value: c._id,
            label: c.category,
          });
        }

        setCategory(cOptions);
      });
  };

  const loadSubCategories = () => {
    setSubCategory([]);
    setNscf(false);

    fetch(`https://api.soillight.com/sub/category/${selectedCat}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userLogin?.user?.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const cOptions = [];

        for (let c of data?.data) {
          cOptions.push({
            value: c._id,
            label: c.subCategory,
          });
        }

        if (!Boolean(cOptions.find((o) => o.value === selectedSubCat))) {
          if (cOptions.length !== 0) {
            setSelectedSubCat(cOptions[0].value);
          }
        }

        if (cOptions.length === 0) {
          setNscf(true);
        }

        setSubCategory(cOptions);
        console.log("sub cat set.");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    console.log("Loading...");
    loadSubCategories();
  }, [selectedCat]);

  const updateInsideSubCategory = (data) => {
    setLoading(true);
    fetch(
      `https://api.soillight.com/inside/pack/type/${packType?._id}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
        body: JSON.stringify({
          ...data,
          category: selectedCat,
          subCategory: selectedSubCat,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        reset();

        if (data.message) {
          toast(data.message);
          window.location.reload();
        }
        if (data.error) {
          Object.values(data.error).forEach((err) => {
            toast(err);
          });
        }
      });
  };
  const deleteInsideSubCategory = () => {
    if (!packType?._id) return;

    setLoading(true);

    fetch(
      `https://api.soillight.com/inside/pack/type/${packType?._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        reset();
        setLoading(false);

        if (data.message) {
          toast(data.message);
          window.location.reload();
        }
        if (data.error) {
          Object.values(data.error).forEach((err) => {
            toast(err);
          });
        }
      });
  };

  return (
    <div>
      <Modal
        style={{ overflowY: "scroll" }}
        open={packTypeOpen}
        onClose={handlePackTypeDetailsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              fontSize: "20px",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Pack Type
            </Typography>
            <MdCancel onClick={handlePackTypeDetailsClose} />
          </div>
          <form onSubmit={handleSubmit(updateInsideSubCategory)}>
            <Box mb={2}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: 14,
                  color: "#464646",
                }}
                style={{ fontFamily: `"Poppins", sans-serif` }}
              >
                Category
              </Typography>

              {category.length === 0 ? (
                "Loading..."
              ) : (
                <Select
                  options={category}
                  defaultValue={category.find((c) => c.value === selectedCat)}
                  onChange={(i) => setSelectedCat(i.value)}
                />
              )}
            </Box>

            <Box mb={2}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: 14,
                  color: "#464646",
                }}
                style={{ fontFamily: `"Poppins", sans-serif` }}
              >
                Sub Category
              </Typography>

              {subCategory.length === 0 ? (
                nscf ? (
                  "No sub category found."
                ) : (
                  "Loading..."
                )
              ) : (
                <Select
                  options={subCategory}
                  defaultValue={
                    subCategory.find((c) => c.value === selectedSubCat) ||
                    subCategory[0]
                  }
                  onChange={(i) => setSelectedSubCat(i.value)}
                />
              )}
            </Box>

            <Box mb={2}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: 14,
                  color: "#464646",
                }}
                style={{ fontFamily: `"Poppins", sans-serif` }}
              >
                Pack Type
              </Typography>
              {/* {console.log(insideSubCategory)} */}
              <TextField
                fullWidth
                placeholder={packType?.packType}
                size="small"
                {...register("packType", { min: 0 })}
                defaultValue={packType?.packType}
                required
              />
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    type="submit"
                    style={{ textTransform: "capitalize", marginTop: "30px" }}
                    variant="contained"
                  >
                    Update
                  </Button>
                  <Button
                    onClick={deleteInsideSubCategory}
                    style={{
                      textTransform: "capitalize",
                      marginTop: "30px",
                      marginLeft: "20px",
                      background: "red",
                    }}
                    variant="contained"
                  >
                    Delete
                  </Button>
                </>
              )}
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
