import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

import {
  CATEGORY_STORE,
  PROGRESS_CATEGORIES,
} from "../../../management/reducers/AllCetegoryReducer";
const style = {
  position: "absolute",
  top: "60%",
  left: "50%",
  border: "none",
  width: "400px",
  outline: "none",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
};

export default function CreateSubCategory({
  createSubCategoryOpen,
  handleSubCategoryClose,
  setSubCategoryOpen,
}) {
  const dispatch = useDispatch();
  const { register, reset, handleSubmit } = useForm();
  const { userLogin, category } = useSelector((state) => state);
  const [categorySearch, setCategorySearch] = useState("");
  const [previewSource, setPreviewSource] = useState(null);

  const handleSelectFile = (e) => {
    if (e.target?.files?.length) {
      setPreviewSource(e.target?.files[0]);
    }
  };

  useEffect(() => {
    dispatch({
      type: PROGRESS_CATEGORIES,
      payload: {
        loading: true,
      },
    });
    fetch(
      `https://api.soillight.com/category?page=1&limit=500&search=${
        categorySearch || ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: PROGRESS_CATEGORIES,
          payload: {
            loading: false,
          },
        });
        // console.log(data)
        dispatch({
          type: CATEGORY_STORE,
          payload: {
            category: data.data,
          },
        });
      });
  }, [categorySearch, dispatch, userLogin?.user?.token]);

  const createSubCategory = (data) => {
    dispatch({
      type: PROGRESS_CATEGORIES,
      payload: {
        loading: true,
      },
    });

    const formData = new FormData();
    formData.append("subCategory", data.subCategory);
    formData.append("category", data.category);
    formData.append("img", previewSource);

    axios
      .post(`https://api.soillight.com/sub/category`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userLogin?.user?.token}`,
        },
      })
      .then(({ data }) => {
        reset();
        dispatch({
          type: PROGRESS_CATEGORIES,
          payload: {
            loading: false,
          },
        });
        if (data.message) {
          toast(data.message);
          setPreviewSource(null);
        }
        if (data.error) {
          Object.values(data.error).forEach((err) => {
            toast(err);
          });
        }
      });
  };
  return (
    <div>
      <Modal
        style={{ overflowY: "scroll" }}
        open={createSubCategoryOpen}
        onClose={handleSubCategoryClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={handleSubCategoryClose}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create Sub Category
            </Typography>
            <MdCancel />
          </div>
          <form onSubmit={handleSubmit(createSubCategory)}>
            <Typography
              sx={{
                fontWeight: "bold",
                mb: 1,
                fontSize: 14,
                color: "#464646",
              }}
              style={{ fontFamily: `"Poppins", sans-serif` }}
            >
              Choose a Category:
            </Typography>
            <TextField
              fullWidth
              size="small"
              type="text"
              onChange={(e) => setCategorySearch(e.target.value)}
              placeholder="Search Category..."
            />
            <select
              id="category"
              style={{
                padding: "4px 10px",
                width: "100%",
                fontSize: "14px",
                color: "gray",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
              {...register("category", { min: 0 })}
              required
            >
              {category?.category.map((category, index) => (
                <option key={index} value={category?._id}>
                  {" "}
                  {category?.category}
                </option>
              ))}
            </select>

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: 14,
                  color: "#464646",
                }}
                style={{ fontFamily: `"Poppins", sans-serif` }}
              >
                Sub Category Name
              </Typography>
              <TextField
                fullWidth
                placeholder="Sub Category Name"
                size="small"
                {...register("subCategory", { min: 0 })}
                required
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "10px 0px",
              }}
            >
              <>
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    position: "absolute",
                    zIndex: "-1",
                    borderRadius: "100%",
                  }}
                  src={
                    previewSource
                      ? URL.createObjectURL(previewSource)
                      : "https://dummyimage.com/100x100/aaa/000"
                  }
                  alt="chosen"
                />
                <label
                  style={{
                    opacity: 0,
                    background: "transparent",
                    cursor: "pointer",
                    padding: "40px",
                    border: "none",
                  }}
                >
                  <input
                    sx={{
                      color: "white",
                      opacity: 0,
                      height: "100px",
                      padding: "30px 30px!important",
                    }}
                    onChange={handleSelectFile}
                    type="file"
                  />
                </label>
              </>
            </Box>

            <Box display="flex" justifyContent="center">
              {category.loading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  style={{ textTransform: "capitalize", marginTop: "30px" }}
                  variant="contained"
                >
                  Create Sub Category
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
