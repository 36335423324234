import * as React from 'react';
import ModalContext from './context';
import reducer, { initialState } from './reducer';
import actions from './actions';
import { ModalContextType } from './types';

const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);


  const stateD: Pick<ModalContextType, 'state'> = React.useMemo(
    () => ({
      state,
    }),
    [state],
  );

  const dispatches: Omit<ModalContextType, 'state'> = React.useMemo(() => ({openModal: actions.openModal(dispatch),}),
    [dispatch],
  );

  return (
    <ModalContext.Provider value={{ ...stateD, ...dispatches }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
