import { ModalStateType } from "./types";
import { SET_MODAL } from "..";

export const ADD_NEW_STAFFS = "ADD_NEW_STAFFS";

export const initialState: ModalStateType = {
  modal: undefined,
  data: undefined,
};

const reducer = (
  state: ModalStateType = initialState,
  action: any
): ModalStateType => {
  const current: ModalStateType = state;
  const modal = action.payload?.modal ?? "";
  const data = action.payload?.data ?? undefined;
  switch (action.type) {
    case SET_MODAL:
      return { modal, data };
    default:
      return current;
  }
};

export default reducer;
