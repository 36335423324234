/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */

import {
  SET_ERROR,
  SET_SUCCESS,
  SET_PRODUCTS,
  SET_CATEGORIES,
  SET_CATEGORIES_LOADING,
  SET_PACKTYPES,
  SET_PACKTYPE_LOADING,
  SET_PRODUCTS_LOADING,
  SET_SUBCATEGORIES,
  SET_SUBCATEGORIES_LOADING,
  SET_TYPES,
  SET_TYPES_LOADING,
  SET_LOADING,
  CLEAR_PRODUCT_STATE,
} from "..";

export const initialState = {
  products: [] as Record<string, any>[],
  categories: [] as Record<string, any>[],
  subCategories: [] as Record<string, any>[],
  types: [] as Record<string, any>[],
  packTypes: [] as Record<string, any>[],
  success: undefined,
  error: undefined,
  isProductsLoading: false,
  isCategoriesLoading: false,
  isSubCategoriesLoading: false,
  isTypesLoading: false,
  isPackTypesLoading: false,
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  const current = state;
  const products = action.payload?.products ?? [];
  const categories = action.payload?.categories ?? [];
  const subCategories = action.payload?.subCategories ?? [];
  const types = action.payload?.types ?? [];
  const packTypes = action.payload?.packTypes ?? [];
  const isProductsLoading = action.payload?.isProductsLoading ?? false;
  const isCategoriesLoading = action.payload?.isCategoriesLoading ?? false;
  const isSubCategoriesLoading =
    action.payload?.isSubCategoriesLoading ?? false;
  const isTypesLoading = action.payload?.isTypesLoading ?? false;
  const isPackTypesLoading = action.payload?.isPackTypesLoading ?? false;

  const error = action.payload?.error;
  const success = action.payload?.success;

  const loading = action.payload?.loading ?? false;
  const loadingType = action.payload?.loadingType ?? "";

  switch (action.type) {
    case SET_ERROR:
      // eslint-disable-next-line no-console
      console.log(error);
      return { ...current, error };
    case SET_SUCCESS:
      // eslint-disable-next-line no-console
      console.log(success);
      return { ...current, success };

    case SET_PRODUCTS:
      return {
        ...current,
        products,
      };
    case SET_CATEGORIES:
      return {
        ...current,
        categories,
      };
    case SET_SUBCATEGORIES:
      return {
        ...current,
        subCategories,
      };
    case SET_TYPES:
      return {
        ...current,
        types,
      };
    case SET_PACKTYPES:
      return {
        ...current,
        packTypes,
      };
    case SET_PRODUCTS_LOADING:
      return { ...current, isProductsLoading };
    case SET_CATEGORIES_LOADING:
      return { ...current, isCategoriesLoading };
    case SET_SUBCATEGORIES_LOADING:
      return { ...current, isSubCategoriesLoading };
    case SET_TYPES_LOADING:
      return { ...current, isTypesLoading };
    case SET_PACKTYPE_LOADING:
      return { ...current, isPackTypesLoading };
    case "CLEAR_TYPE_STATE":
      return { ...current, types: [] };
    case "CLEAR_SIZE_STATE":
      return { ...current, packTypes: [] };
    case "CLEAR_ERROR":
      // eslint-disable-next-line no-console
      console.log(error);
      return { ...current, error: "" };
    case "CLEAR_SUCCESS":
      // eslint-disable-next-line no-console
      console.log(error);
      return { ...current, success: "" };
    case SET_LOADING:
      return { ...current, loading, loadingType };
    case CLEAR_PRODUCT_STATE:
      return { ...current, products: [] };

    default:
      return current;
  }
};

export default reducer;
