import { Grid, Typography, Box, Card, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdNotificationAdd } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "./Sheard/DashboardHeader";
import NewCampaignMedal from "./Sheard/NewCampaignMedal";
import SearchListBuyer from "./Sheard/SearchListBuyer";
import SearchProfileView from "./Sheard/SearchProfileView";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
const Promotions = () => {
  const navigate = useNavigate();

  const [createPM, setCreatePM] = useState(false);

  const [promotions, setPromotions] = useState([]);
  const [selected, setSelected] = useState();

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const loadPromotions = async () => {
    try {
      const { data } = await axios.get(
        "https://api.soillight.com/promotions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setPromotions(data?.promotions);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleSendNotificationAgain = (id) => {
    toast.promise(
      axios.get(
        `https://api.soillight.com/promotions/${id}/re-sent-notification`,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      ),
      {
        pending: {
          render: "Sending...",
        },
        success: {
          render() {
            return "Notification re-send successful!";
          },
        },
        error: {
          render({ data }) {
            return data.message;
          },
        },
      }
    );
  };

  const handleDelete = (id) => {
    toast.promise(
      axios.delete(`https://api.soillight.com/promotions/${id}`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      }),
      {
        pending: {
          render: "Deleting promotion...",
        },
        success: {
          render() {
            setPromotions((prev) => {
              return prev.filter((p) => p._id !== id);
            });
            setSelected(null);

            return "Promotion successfully deleted.";
          },
        },
        error: {
          render({ data }) {
            return data.message;
          },
        },
      }
    );
  };

  useEffect(() => {
    loadPromotions();
  }, []);

  return (
    <div>
      <NewCampaignMedal
        open={createPM}
        onHide={() => setCreatePM(false)}
        onCreate={(p) => setPromotions((prev) => [p, ...prev])}
      />
      <DashboardHeader title="Promotions" />
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            bgcolor: "#f7f7fa",
            height: "100vh",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <h4 className="search-title">Promotions</h4>

            <MdNotificationAdd
              onClick={() => setCreatePM(!createPM)}
              style={{ fontSize: "20px", cursor: "pointer" }}
            />
          </div>

          <Box>
            {promotions.map((p, i) => (
              <Box
                key={i}
                component={Card}
                sx={{
                  mb: 2,
                  mr: 2,
                  p: 2,
                  cursor: "pointer",
                  ":hover": {
                    bgcolor: "rgba(255,255,255,0.2)",
                  },
                }}
                onClick={() => setSelected(p)}
              >
                <Typography variant="h6">{p.name}</Typography>
                <Typography variant="caption">
                  Created At: {moment(p.createdAt).fromNow()}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          {!Boolean(selected) && (
            <Box sx={{ p: 5 }}>
              <Typography variant="h5">
                Select a promotion to see details.
              </Typography>
            </Box>
          )}

          {Boolean(selected) && (
            <Box sx={{ p: 5 }}>
              <Typography variant="h4" sx={{ my: 2 }}>
                Basic Info
              </Typography>

              <Typography variant="h6">
                <strong>Name:</strong> {selected?.name}
              </Typography>
              <Typography variant="h6">
                <strong>Audience:</strong>{" "}
                {selected?.audience === "all"
                  ? "All users"
                  : selected?.audience}
              </Typography>
              <Typography variant="h6">
                <strong>Promotion Type:</strong> {selected?.type}
              </Typography>

              {selected?.type === "DISCOUNT" && (
                <Typography variant="h6">
                  <strong>Discount parentage:</strong>{" "}
                  {selected?.discountParentage}%
                </Typography>
              )}

              {selected?.type !== "PUSH_NOTIFICATION" && (
                <Typography variant="h6">
                  <strong>Validity:</strong>{" "}
                  {moment(selected?.validity).format("lll")}
                </Typography>
              )}

              <Typography variant="h4" sx={{ my: 2 }}>
                Notification Info
              </Typography>

              <Typography variant="h6">
                <strong>Title:</strong> {selected?.title}
              </Typography>

              <Typography variant="h6">
                <strong>Body:</strong> {selected?.body}
              </Typography>

              <Box sx={{ my: 4, display: "flex", justifyContent: "center" }}>
                <Button
                  color="error"
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={() => handleDelete(selected._id)}
                >
                  Delete Promotion
                </Button>
                <Button
                  color="success"
                  variant="outlined"
                  onClick={() => handleSendNotificationAgain(selected._id)}
                >
                  Send Notification Again
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Promotions;
