import { SET_MODAL } from "..";

const openModal =
  (dispatch: React.Dispatch<any>) => (routeName?: string, data?: unknown) => {
    dispatch({
      type: SET_MODAL,
      payload: { modal: routeName, data },
    });
  };

export default {
  openModal,
};
