import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { logOut } from "./management/actions/userActions";
import AdminProtect from "./pages/Authentication/AdminProtect";
import ChangePassword from "./pages/Authentication/ChangePassword";
import EditProfile from "./pages/Authentication/EditProfile";
import Login from "./pages/Authentication/Login";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import Buyer from "./pages/Dashboard/Buyer";
import Categories from "./pages/Dashboard/Categories";
import DashBoard from "./pages/Dashboard/DashBoard";
import Order from "./pages/Dashboard/Order";
import Product from "./pages/Dashboard/Product";
import Profile from "./pages/Dashboard/Profile";
import Rider from "./pages/Dashboard/Rider";
import Seller from "./pages/Dashboard/Seller";
import WithdrawTransaction from "./pages/Dashboard/WithdrawTransaction";
import DashboardHome from "./pages/DashBoardHome/DashboardHome";
import Promotions from "./pages/Dashboard/Promotions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState, lazy, Suspense } from "react";
import { useModal } from "src/state";
import { Modal } from "src/containers";
import ModalProvider from "./state/Modals/provider";
import SellerProvider from "./state/Seller/provider";
import { ErrorHandler } from "./containers/Error";
import { SuccessHandler } from "./containers/Success";
import { SnackbarProvider } from 'notistack';

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const dispatch = useDispatch();
  const handleLogOut = () => {
    dispatch(logOut());
  };
  return (
    <div className="App">
      <ToastContainer
        style={{ color: "green", marginTop: "10px" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ModalProvider>
          <SellerProvider>
            <SuccessHandler>
              <ErrorHandler>
                <ModalManager>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<Login />}></Route>
                      <Route path="/login" element={<Login />}></Route>
                      <Route
                        path="/forget-password"
                        element={<ForgetPassword />}
                      ></Route>
                      <Route
                        path="/permission"
                        element={
                          <>
                            {!user?.data?.email && (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "50px",
                                }}
                              >
                                Deny permission! You can only access this page
                                from the admin <Link to="/login">Login</Link>
                              </div>
                            )}
                            {user?.data?.email && (
                              <>
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: "50px",
                                  }}
                                >
                                  Deny permission! You can only access this page
                                  from the admin
                                </p>
                                <h2
                                  onClick={handleLogOut}
                                  style={{
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  Log Out
                                </h2>{" "}
                              </>
                            )}
                          </>
                        }
                      ></Route>
                      <Route
                        path="/dashboard"
                        element={<DashboardHome></DashboardHome>}
                      >
                        <Route
                          path="dashboard"
                          element={
                            <AdminProtect>
                              <DashBoard />{" "}
                            </AdminProtect>
                          }
                        />
                        <Route path="order" element={<Order />} />
                        <Route path="product" element={<Product />} />
                        <Route
                          path="withdraw/transaction"
                          element={<WithdrawTransaction />}
                        />
                        <Route path="buyer" element={<Buyer />} />
                        <Route path="seller" element={<Seller />} />
                        <Route path="rider" element={<Rider />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="categories" element={<Categories />} />
                        <Route path="promotions" element={<Promotions />} />
                        <Route path="edit-profile" element={<EditProfile />} />
                        <Route
                          path="change-password"
                          element={<ChangePassword />}
                        />
                      </Route>
                      <Route
                        path="*"
                        element={<h2>Not Founds 404!</h2>}
                      ></Route>
                    </Routes>
                  </BrowserRouter>
                </ModalManager>
              </ErrorHandler>
            </SuccessHandler>
          </SellerProvider>
        </ModalProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;

const modalRoutes = [
  {
    path: "#success",
    component: lazy(() => import("src/modals/Success")),
    isModal: true,
  },

  {
    path: "#create-product",
    component: lazy(() => import("src/modals/CreateProduct")),
    isModal: true,
  },
  {
    path: "#edit-product",
    component: lazy(() => import("src/modals/EditProduct")),
    isModal: true,
  },
  {
    path: "#delete-product",
    component: lazy(() => import("src/modals/DeleteProduct")),
    isModal: true,
  },

  {
    path: "#loading",
    component: lazy(() => import("src/modals/Loading")),
    dismissible: false,
    opaque: true,
    isModal: true,
  },
];

const ModalManager = ({ children }) => {
  const [indexToRender, setIndexToRender] = useState(-1);
  const { state } = useModal();
  useEffect(() => {
    if (state.modal) {
      const { modal } = state;
      setIndexToRender(
        modalRoutes.findIndex(({ path }) => path?.replace("#", "") === modal)
      );
    } else {
      setIndexToRender(-1);
    }
  }, [state]);

  const ModalPage = modalRoutes[indexToRender]?.component;
  return (
    <>
      {children}
      {ModalPage && (
        <Modal
          dismissible={modalRoutes[indexToRender]?.dismissible}
          opaque={modalRoutes[indexToRender]?.opaque}
        >
          <Suspense fallback={<>Loading ...</>}>
            {ModalPage && <ModalPage />}
          </Suspense>
        </Modal>
      )}
    </>
  );
};
