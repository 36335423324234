/* eslint-disable no-underscore-dangle */
import * as React from "react";
import { requestHelper, getUserToken } from "src/utils/functions";
import {
  SET_ERROR,
  SET_SUCCESS,
  SET_LOADING,
  SET_PRODUCTS_LOADING,
  SET_PRODUCTS,
  SET_CATEGORIES_LOADING,
  SET_CATEGORIES,
  SET_SUBCATEGORIES_LOADING,
  SET_TYPES,
  SET_PACKTYPE_LOADING,
  SET_PACKTYPES,
  SET_TYPES_LOADING,
  SET_SUBCATEGORIES,
  CLEAR_PRODUCT_STATE,
} from "..";

const logError = (dispatch: React.Dispatch<any>) => (error: string) => {
  dispatch({ type: SET_ERROR, payload: { error } });
};

const logSuccess = (dispatch: React.Dispatch<any>) => (success: string) => {
  dispatch({ type: SET_SUCCESS, payload: { success } });
};

const getProducts =
  (dispatch: React.Dispatch<any>) => async (data: { sellerId: string }) => {
    try {
      clearProductState(dispatch)();
      dispatch({
        type: SET_PRODUCTS_LOADING,
        payload: {
          isProductsLoading: true,
        },
      });

      const res = await requestHelper("products/my/" + data.sellerId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      });

      if (!res.success) {
        dispatch({
          type: SET_PRODUCTS_LOADING,
          payload: {
            isProductsLoading: false,
          },
        });

        logError(dispatch)(res.data.message || "Error getting products");
      }

      dispatch({
        type: SET_PRODUCTS,
        payload: {
          products: (res.data?.data as Record<string, any>[]) ?? [],
        },
      });

      dispatch({
        type: SET_PRODUCTS_LOADING,
        payload: {
          isProductsLoading: false,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_PRODUCTS_LOADING,
        payload: {
          isProductsLoading: false,
        },
      });
    }
  };

const getCategories =
  (dispatch: React.Dispatch<any>) => async (data?: Record<string, any>) => {
    try {
      dispatch({
        type: SET_CATEGORIES_LOADING,
        payload: {
          isCategoriesLoading: true,
        },
      });

      const res = await requestHelper("category", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      });

      if (!res.success) {
        dispatch({
          type: SET_CATEGORIES_LOADING,
          payload: {
            isCategoriesLoading: false,
          },
        });

        logError(dispatch)(res.data.message || "Error getting categories");
      }

      dispatch({
        type: SET_CATEGORIES,
        payload: {
          categories: (res.data?.data as Record<string, any>[]) ?? [],
        },
      });

      dispatch({
        type: SET_CATEGORIES_LOADING,
        payload: {
          isCategoriesLoading: false,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_CATEGORIES_LOADING,
        payload: {
          isCategoriesLoading: false,
        },
      });
    }
  };

const getSubCategories =
  (dispatch: React.Dispatch<any>) => async (data: { categoryId: string }) => {
    try {
      dispatch({
        type: SET_SUBCATEGORIES_LOADING,
        payload: {
          isSubCategoriesLoading: true,
        },
      });

      const res = await requestHelper("sub/category/" + data.categoryId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      });

      if (!res.success) {
        dispatch({
          type: SET_SUBCATEGORIES_LOADING,
          payload: {
            isSubCategoriesLoading: false,
          },
        });

        logError(dispatch)(res.data.message || "Error getting sub categories");
      }

      dispatch({
        type: SET_SUBCATEGORIES,
        payload: {
          subCategories: (res.data?.data as Record<string, any>[]) ?? [],
        },
      });

      dispatch({
        type: SET_SUBCATEGORIES_LOADING,
        payload: {
          isSubCategoriesLoading: false,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_SUBCATEGORIES_LOADING,
        payload: {
          isSubCategoriesLoading: false,
        },
      });
    }
  };

const getTypes =
  (dispatch: React.Dispatch<any>) =>
  async (data: { subCategoryId: string }) => {
    try {
      dispatch({
        type: SET_TYPES_LOADING,
        payload: {
          isTypesLoading: true,
        },
      });

      const res = await requestHelper(
        "inside/sub/category/" + data.subCategoryId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      );

      if (!res.success) {
        dispatch({
          type: SET_TYPES_LOADING,
          payload: {
            isTypesLoading: false,
          },
        });

        logError(dispatch)(res.data.message || "Error getting types");
      }

      dispatch({
        type: SET_TYPES,
        payload: {
          types: (res.data?.data as Record<string, any>[]) ?? [],
        },
      });

      dispatch({
        type: SET_TYPES_LOADING,
        payload: {
          isTypesLoading: false,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_TYPES_LOADING,
        payload: {
          isTypesLoading: false,
        },
      });
    }
  };

const getPackTypes =
  (dispatch: React.Dispatch<any>) =>
  async (data: { subCategoryId: string }) => {
    try {
      dispatch({
        type: SET_PACKTYPE_LOADING,
        payload: {
          isPackTypesLoading: true,
        },
      });

      const res = await requestHelper(
        "inside/pack/type/" + data.subCategoryId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      );

      if (!res.success) {
        dispatch({
          type: SET_PACKTYPE_LOADING,
          payload: {
            isTypesLoading: false,
          },
        });

        logError(dispatch)(res.data.message || "Error getting types");
      }

      dispatch({
        type: SET_PACKTYPES,
        payload: {
          packTypes: (res.data?.data as Record<string, any>[]) ?? [],
        },
      });

      dispatch({
        type: SET_PACKTYPE_LOADING,
        payload: {
          isPackTypesLoading: false,
        },
      });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_PACKTYPE_LOADING,
        payload: {
          isPackTypesLoading: false,
        },
      });
    }
  };

const createProduct =
  (dispatch: React.Dispatch<any>) =>
  async (data: { payload: Record<string, any>; sellerId: string }) => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: true,
        },
      });

      const res = await requestHelper(
        "products/create-admin/" + data.sellerId,
        {
          method: "POST",
          body: JSON.stringify({ ...data.payload }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      );

      if (!res.success) {
        logError(dispatch)(res.data.product || "Error creating product");
        dispatch({
          type: SET_LOADING,
          payload: {
            loading: false,
          },
        });
        return;
      }

      // dispatch({
      //   type: UPDATE_RECIPES,
      //   payload: {
      //     recipe: res.data.data as Recipe,
      //   },
      // });

      logSuccess(dispatch)("Product created");

      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });

      //fetch products
      getProducts(dispatch)({ sellerId: data.sellerId });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };

const updateProduct =
  (dispatch: React.Dispatch<any>) =>
  async (data: {
    payload: Record<string, any>;
    productId: string;
    sellerId?: string;
  }) => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: true,
        },
      });

      const res = await requestHelper("products/update/" + data.productId, {
        method: "PUT",
        body: JSON.stringify({ ...data.payload }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      });

      if (!res.success) {
        logError(dispatch)(res.data.product || "Error updating product");
        dispatch({
          type: SET_LOADING,
          payload: {
            loading: false,
          },
        });
        return;
      }

      logSuccess(dispatch)("Product updated");

      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });

      //fetch products
      getProducts(dispatch)({ sellerId: data.sellerId ?? "" });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };

const deleteProduct =
  (dispatch: React.Dispatch<any>) =>
  async (data: { productId: string; sellerId?: string }) => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: true,
        },
      });
      const res = await requestHelper("products/remove/" + data.productId, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      });
      if (!res.success) {
        logError(dispatch)(res.data.product || "Error updating product");
        dispatch({
          type: SET_LOADING,
          payload: {
            loading: false,
          },
        });
        return;
      }

      logSuccess(dispatch)("Product deleted");

      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });

      //fetch products
      getProducts(dispatch)({ sellerId: data.sellerId ?? "" });
    } catch (e: unknown) {
      const error = e as Error;
      logError(dispatch)(error.message);
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };

const clearTypeState = (dispatch: React.Dispatch<any>) => () => {
  dispatch({ type: "CLEAR_TYPE_STATE" });
};

const clearSizeState = (dispatch: React.Dispatch<any>) => () => {
  dispatch({ type: "CLEAR_SIZE_STATE" });
};

const clearProductState = (dispatch: React.Dispatch<any>) => () => {
  dispatch({ type: CLEAR_PRODUCT_STATE });
};

const clearError = (dispatch: React.Dispatch<any>) => () => {
  dispatch({ type: "CLEAR_ERROR" });
};

const clearSuccess = (dispatch: React.Dispatch<any>) => () => {
  dispatch({ type: "CLEAR_SUCCESS" });
};

export default {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  getCategories,
  getSubCategories,
  getTypes,
  getPackTypes,

  clearSizeState,
  clearTypeState,
  clearError,
  clearSuccess,
  clearProductState,
};
