import { theme, font } from 'src/utils/variables';
import styled, { css } from 'styled-components';
import { InputProps } from 'src/types';
import { Row } from '../Flex';

export const InputContainer = styled(Row)`
  position: relative;
  & > svg,
  path {
    height: 100%;
    width: 2.75rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    left: 1rem;
    background: transparent;
  }
`;

export const InputBase = styled.input<InputProps>`
  :focus {
    outline: none !important;
    border: 2px solid #05AC54;
  }
  -webkit-align-items: center;
  display: -webkit-inline-flex;
  overflow: hidden;
  ${({ padding = 10 }: InputProps) =>
    padding &&
    css`
      padding: ${padding}px;
    `}
  width: 100%;
  font-size: 14px;
  -moz-appearance: textfield;
  height: 45px;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1.5px solid #05AC54;
  -webkit-padding-start: 15px;
  ${({ suffixIcon }: InputProps) =>
    suffixIcon &&
    css`
      -webkit-padding-start: 2.7rem;
    `}
  ${({ align = 'left' }: InputProps) => {
    if (align === 'center')
      return css`
        -webkit-padding-start: 0;
        text-align: ${align};
      `;
    return css`
      text-align: ${align};
    `;
  }}
  :hover {
    outline: none !important;
    border: 2px solid #05AC54;
  }
`;
