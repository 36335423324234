export * from "./Modals";
export * from "./Seller";

export const INIT = "INIT";

export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";

export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_SUBCATEGORIES = "SET_SUBCATEGORIES";
export const SET_TYPES = "SET_TYPES";
export const SET_PACKTYPES = "SET_PACKTYPES";

export const SET_LOADING = "SET_LOADING";
export const SET_PRODUCTS_LOADING = "SET_PRODUCTS_LOADING";
export const SET_CATEGORIES_LOADING = "SET_CATEGORIES_LOADING";
export const SET_SUBCATEGORIES_LOADING = "SET_SUBCATEGORIES_LOADING";
export const SET_TYPES_LOADING = "SET_TYPES_LOADING";
export const SET_PACKTYPE_LOADING = "SET_PACKTYPE_LOADING";

export const SET_MODAL = "SET_MODAL";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SET_PAGINATION_INFO = "SET_PAGINATION_INFO";
export const CLEAR_PRODUCT_STATE = "CLEAR_PRODUCT_STATE";
