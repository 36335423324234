import * as React from "react";
import SellerContext from "./context";
import reducer, { initialState } from "./reducer";
import actions from "./actions";

const SellerProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const stateD = React.useMemo(
    () => ({
      state,
    }),
    [state]
  );

  const dispatches = React.useMemo(
    () => ({
      getProducts: actions.getProducts(dispatch),
      getCategories: actions.getCategories(dispatch),
      getSubCategories: actions.getSubCategories(dispatch),
      getTypes: actions.getTypes(dispatch),
      getPackTypes: actions.getPackTypes(dispatch),
      createProduct: actions.createProduct(dispatch),
      updateProduct: actions.updateProduct(dispatch),
      deleteProduct: actions.deleteProduct(dispatch),
      clearSizeState: actions.clearSizeState(dispatch),
      clearTypeState: actions.clearTypeState(dispatch),
      clearSuccess: actions.clearSuccess(dispatch),
      clearError: actions.clearError(dispatch),
      clearProductState: actions.clearProductState(dispatch),
    }),
    [dispatch]
  );

  return (
    <SellerContext.Provider value={{ ...stateD, ...dispatches }}>
      {children}
    </SellerContext.Provider>
  );
};

export default SellerProvider;
