import React, { useEffect } from "react";
import { useSeller, useModal } from "src/state";
import { useSnackbar } from "notistack";

export const SuccessHandler: React.FC = ({ children }) => {
  const { state: sellerState, clearSuccess: clearSellerSuccess } = useSeller();

  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  useEffect(() => {
    if (sellerState.success) {
      enqueueSnackbar(sellerState.success, {
        variant: "success",
        autoHideDuration: 2000,
      });
      clearSellerSuccess();
      openModal();
    }
  }, [sellerState.success]);

  return <>{children}</>;
};
